import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./index/index.module').then((m) => m.IndexPageModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'splash',
    loadChildren: () =>
      import('./splash/splash.module').then((m) => m.SplashPageModule),
  },
  {
    path: 'image-show',
    loadChildren: () => import('./pages/fuelmodule/image-show/image-show.module').then( m => m.ImageShowPageModule)
  },
  {
    path: 'unloading-form',
    loadChildren: () => import('./pages/delivery-module/unloading-form/unloading-form.module').then( m => m.UnloadingFormPageModule)
  },
  {
    path: 'consignment-list',
    loadChildren: () => import('./pages/booking-module/consignment-list/consignment-list.module').then( m => m.ConsignmentListPageModule)
  },
  {
    path: 'ident-generation-list',
    loadChildren: () => import('./pages/indentmodule/ident-generation-list/ident-generation-list.module').then( m => m.IdentGenerationListPageModule)
  } 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
