import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
@Component({
  selector: 'app-gate-pass-print-modal',
  templateUrl: './gate-pass-print-modal.component.html',
  styleUrls: ['./gate-pass-print-modal.component.scss'],
})
export class GatePassPrintModalComponent implements OnInit {
  @Input() order;
  _complaintsModel = [
    {
      ROWNO: '1',
      GPLD_COMPLAINTS: 'ENGINE OIL CHECK',
      GPLD_STATUS: 'N',
    },
    {
      ROWNO: '2',
      GPLD_COMPLAINTS: 'COOLANT WATER CHECK',
      GPLD_STATUS: 'N',
    },
    {
      ROWNO: '3',
      GPLD_COMPLAINTS: 'STEERING TQ OIL CHECK',
      GPLD_STATUS: 'N',
    },
    {
      ROWNO: '4',
      GPLD_COMPLAINTS: 'AIR PRESSOR LEAKAGE',
      GPLD_STATUS: 'N',
    },
    {
      ROWNO: '5',
      GPLD_COMPLAINTS: 'SPEEDO METER CHECK',
      GPLD_STATUS: 'N',
    },
    {
      ROWNO: '6',
      GPLD_COMPLAINTS: 'SPRING PATTA BROKEN',
      GPLD_STATUS: 'N',
    },
    {
      ROWNO: '7',
      GPLD_COMPLAINTS: 'ALLIGNMENT CHECK',
      GPLD_STATUS: 'N',
    },
    {
      ROWNO: '8',
      GPLD_COMPLAINTS: 'HUB STUD CHECK',
      GPLD_STATUS: 'N',
    },
    {
      ROWNO: '9',
      GPLD_COMPLAINTS: 'DRIVER TOOLS',
      GPLD_STATUS: 'N',
    },
  ];
  content: string;
  constructor(
    private modalContrller: ModalController,
    private pdfGenerator: PDFGenerator
  ) {}
  closeModal() { 
    this.modalContrller.dismiss();
  }
  downloadInvoice() {
    this.content = document.getElementById('PrintInvoice').innerHTML;
    let options = {
      documentSize: 'A4',
      type: 'share',
      fileName: this.order['Main'][0]['VEHICLE']+'.pdf',
    };
    this.pdfGenerator
      .fromData(this.content, options)
      .then((base64) => {
        console.log('OK', base64);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }
  ngOnInit() {
    console.log('Invoice Page2', this.order);
     if ( this.order['Details'].length > 0) {
        this.order['Details'].forEach((y) => {
         this._complaintsModel.forEach((x) => {
           if (y.GPLD_COMPLAINTS == x.GPLD_COMPLAINTS) {
             x.GPLD_STATUS = y.GPLD_STATUS == 'Y' ? 'Y' : 'N';
           }
         });
       });
     }
  }
}
