import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IonSearchbar } from '@ionic/angular';
import {  debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit, AfterViewInit {
  // searchField: any='';
  public searchField: FormControl;
  @Input() placeholder: any = '';
  @Output() onSerach = new EventEmitter<any>();
  @ViewChild('search', { static: false })
  SearchFormControl: IonSearchbar;
  constructor() {
    this.searchField = new FormControl();
  }

  ngOnInit() {}
  searchResults = (_search) => {
    this.onSerach.emit(_search);
  };
  ngAfterViewInit(): void {   
    this.SearchFormControl.value = null;
    this.searchField.valueChanges
      .pipe(debounceTime(700))
      .subscribe((search) => {
        this.searchResults(search);
      });
  }
  ionViewWillEnter() {
     this.SearchFormControl.value = null;
     this.searchField.valueChanges
       .pipe(debounceTime(700))
       .subscribe((search) => {
         this.searchResults(search);
       });
  };
}
