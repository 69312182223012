import { CommonfunctionProvider } from 'src/app/providers/commonfunction';
import { ApiserviceProvider } from 'src/app/providers/apiservice';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {
  constructor(public storage: ApiserviceProvider,public _comn:CommonfunctionProvider) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let loadingContainer: HTMLElement = document
      .getElementsByClassName("loading")
      .item(0) as HTMLElement;
    loadingContainer.style.display = "block";
    if (this.storage._baseToken) {
      
      if(req.url.indexOf("UserAuth?") > 0){
        req = req.clone({
          headers: new HttpHeaders({
            'x-access-token': this.storage._baseToken,
            Authorization: this.storage._accessKey,
            'Access-Control-Allow-Origin': '*',
          }),
        });
      }else if(req.url.indexOf("GetUserInfo?") >0){
        req = req.clone({
          headers: new HttpHeaders({ 'x-access-token': this.storage._baseToken,'Authorization': this.storage._accessKey,'Access-Control-Allow-Origin':'*' }),
        });
      }
      else{
        req = req.clone({
          headers: new HttpHeaders({
            'x-access-token': this.storage._baseToken,
            Authorization: this.storage._accessKey,
            Auth: 
              this.storage.userData.USER.length > 0
                ? this.storage.userData.USER[0]['USR_EMAIL'] +
                  ',' +
                  this.storage.userData.USER[0]['USR_ID']
                : '',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          }),
        });
      }
    }

   // req = req.clone({headers: new HttpHeaders({'Content-Type': 'application/json'})})
   
    return next.handle(req).pipe(
      tap(
        (event) => {
          if (navigator.onLine) {
            if (event instanceof HttpResponse) {
              setTimeout(() => {
                loadingContainer.style.display = "none";
              }, 500);
            }
          } else {
            setTimeout(() => {
              loadingContainer.style.display = "none";
            }, 500);
            this._comn.showError("You are offline.")
          }
        },
        (error) => {
          setTimeout(() => {
            loadingContainer.style.display = "none";
          }, 500);
          if (navigator.onLine) {          
          console.error('NICE ERROR', error.message);
          this._comn.showError(error.message)
          } else {
            this._comn.showError("You are offline.")
          }
        }
      )
    );
  }


}
