import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlidesComponent } from './slides/slides.component';
import { StartComponent } from './start/start.component';
import { LogoComponent } from './logo/logo.component';
import { HeaderComponent } from './header/header.component';
import { ExpandableComponent } from './expandable/expandable.component';
import { ActionsheetComponent } from './actionsheet/actionsheet.component';
import { IonicModule } from '@ionic/angular';
import { CreateActionComponent } from './create-action/create-action.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { PagingComponent } from './paging/paging.component';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { SignaturePadModule } from 'angular2-signaturepad';
import { FooterComponent } from './footer/footer.component';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { GatePassPrintModalComponent } from './gate-pass-print-modal/gate-pass-print-modal.component';

@NgModule({
  declarations: [
    PagingComponent,
    SlidesComponent,
    StartComponent,
    LogoComponent,
    HeaderComponent,
    ExpandableComponent,
    ActionsheetComponent,
    CreateActionComponent,
    SearchFilterComponent,
    FooterComponent,
    GatePassPrintModalComponent,
  ],
  exports: [
    PagingComponent,
    SlidesComponent,
    StartComponent,
    LogoComponent,
    HeaderComponent,
    ExpandableComponent,
    ActionsheetComponent,
    CreateActionComponent,
    SearchFilterComponent,
    FooterComponent,
    GatePassPrintModalComponent,
  ],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
  providers: [
    Geolocation,
    NativeGeocoder,
    AndroidPermissions,
    SignaturePadModule,
    PreviewAnyFile,
  ],
})
export class ComponentsModule {}
