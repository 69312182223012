import { ApiserviceProvider } from 'src/app/providers/apiservice';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { CommonfunctionProvider } from 'src/app/providers/commonfunction';

@Component({
  selector: 'app-actionsheet',
  templateUrl: './actionsheet.component.html',
  styleUrls: ['./actionsheet.component.scss'],
})
export class ActionsheetComponent implements OnInit, AfterViewInit {
  @Output() _onStatusUpdate = new EventEmitter<any>();
  @Output() _onEdit = new EventEmitter<any>();
  @Output() _onPrint = new EventEmitter<any>(); 
  @Input() vno: any;
  @Input() data: any;
  @Input() isShareVisible: boolean = false;
  @Input() isApproveVisible: boolean = true;
  @Input() shareMsg: string = '';
  @Input() actionApprove: boolean;
  @Input() actionAuthorised: boolean;
  @Input() actionCleared: boolean = true;
  @Input() isPrint: boolean = false;
  text: string = '';
  constructor(
    public _alertController: AlertController,
    public _comn: CommonfunctionProvider,
    public _share: ApiserviceProvider
  ) {
    console.log(this._share._menuRights);
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      // this.share();
    }, 100);
  }

  ngOnInit() {}

  showPrompt(_requestFrom, _requestFor) {
    this._alertController
      .create({
        header: `What is the reason for ${_requestFrom} this record ?`,
        inputs: [
          {
            name: 'Reason',
            placeholder: 'write your reason here..',
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            handler: (data: any) => {
              console.log('Canceled', data);
            },
          },
          {
            text: 'Submit',
            handler: (data: any) => {
              if (_requestFor == 'ST') {
                if (
                  (_requestFrom == 'CANCEL' || _requestFrom == 'DELETE') &&
                  data['Reason'] == ''
                ) {
                  this._comn.showError('Please Enter Reason..');
                  return false;
                }
                this.onStatusUpdate(data['Reason'], _requestFrom);
              } else if (_requestFor == 'AT') {
                this.onEdit(data['Reason'], _requestFrom);
              }
            },
          },
        ],
      })
      .then((res) => {
        res.present();
      });
  }

  onStatusUpdate = (_reason, _action) => {
    const _model = {
      vno: this.vno,
      reason: _reason,
      action: _action,
      UserId: this._share.userData.USER[0]['USR_ID'],
    };
    this._onStatusUpdate.emit(_model);
  };
  onEdit = (_reason, _action) => {
    const _model = {
      vno: this.vno,
      reason: _reason,
      action: _action,
    };
    this._onEdit.emit(_model);
  };

  onPrint = () => {
    const _model = {
      vno: this.vno,
    };
    this._onPrint.emit(_model);
  };

  share = async () => {
    var element = document.createElement('a') as HTMLElement;
    element.setAttribute('href', `whatsapp://send?text=${this.shareMsg}`);
    element.setAttribute('style', 'display:none;');
    element.click();
    //  this.data["REFNO"] +'\r\n'+this.data["VEHICLENO"]+'\r\n'+  this.data["DRIVER"]+
    //     '\r\n'+  this.data["ADVTYPE"]+ '\r\n'+ this.data["ADVANCE"] + '\r\n' +this.data["AMOUNT"]+ '\r\n'+ this.data["REMARKS"];

    // await Share.share({
    //   title: '',
    //   text: text,
    //   url: '',
    //   dialogTitle: 'Share with buddies',
    // });
  };
}
