import {EventEmitter, Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private _dataSubject = new Subject<any>();
   
   public __dataSubject = new EventEmitter<any>();

    publishSomeData(data: any) {
        this._dataSubject.next(data);
        this.__dataSubject.emit(data);
    }

    getObservable(): Subject<any> {
        return this._dataSubject;
    }
    getUser(): EventEmitter<any>{
        return this.__dataSubject;
    }
}