import { Injectable } from '@angular/core';
// const CryptoJS = require('crypto-js');
import * as CryptoJS from 'crypto-js';
@Injectable({providedIn:'root'})
export class EncriptionService {
    /**
    * constructor method is used to initialize members of class
    */
    constructor() { }

    /**
    * This method is used to encrypt data with a key
    */
    setEncryptedData(data: any, key) {
        return CryptoJS.AES.encrypt(data, key);
    }

    /**
    * This method is used to decrypt data with a key
    */
    getDecryptedData1(data, key) {        
        const decrypted = CryptoJS.AES.decrypt(data, key);
    if (decrypted) {
      try {
        console.log(decrypted);
        const str = decrypted.toString(CryptoJS.enc.Utf8);
        if (str.length > 0) {
          return str;
        } else {
          return 'error 1';
        } 
      } catch (e) {
        return 'error 2';
      }
    }
    return 'error 3';
    }

    getDecryptedData(data, key) {
      const bytes = CryptoJS.AES.decrypt(data.toString(), key);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
  }
}
