import { ApiserviceProvider } from './providers/apiservice';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { SharedService } from './providers/sharedservice';
import { LocalStorageService } from 'ngx-webstorage';
import { CommonfunctionProvider } from './providers/commonfunction';
import { EncriptionService } from './providers/EncriptionService';
import { distinctUntilChanged } from 'rxjs/operators';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx'
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [StatusBar, SplashScreen],
})
export class AppComponent {
  base64Data: any = '';
  _userData = {
    USER: [],
    COMPANY: [],
    FYEAR: [],
  };
  constructor(
    private menu: MenuController,
    private platform: Platform,
    public router: Router,
    public _share: SharedService,
    public _setting: LocalStorageService,
    public comn: CommonfunctionProvider,
    public _encrpt: EncriptionService,
    public api: ApiserviceProvider,
    public _shared: SharedService,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public _alertController: AlertController,
    private _permisiion: AndroidPermissions
  ) {
    this.initializeApp();
    this._share
      .getObservable()
      .pipe(distinctUntilChanged())
      .subscribe(
        (_res: any) => {
          debugger;
          this._userData = _res;
          if (this._userData) {
            this.api.userData = this._userData;
            if (this._userData.USER[0]['USRIMG']) {
              const arrayBuffer = this._userData.USER[0]['USR_IMG'];
              // const base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
              const base64String = arrayBuffer;

              console.log(base64String);
              this.base64Data = 'data:image/jpeg;base64,' + base64String;
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  openEnd() {
    this.menu.close();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.backgroundColorByHexString('#008b8b');
      console.log('this.router.url', this.router.url);
      const user = this._setting.retrieve('user')
        ? this._encrpt.getDecryptedData(
            this._setting.retrieve('user'),
            'lozicsByBng'
          )
        : null;
      const _accessKey = this._setting.retrieve('accesskey');
      if (_accessKey != null) {
        this._validateDomain(user, _accessKey);
      } else {
        const isLogged = this._setting.retrieve('isFirstime');
        if (isLogged == null) {
          this.router.navigateByUrl('splash');
        } else {
          this.router.navigateByUrl('login');
        }
      }
      this.platform.backButton.subscribeWithPriority(1, () => {
        if (user != null) {
          if (
            this.router.url == '/validate-branch' ||
            this.router.url == '/login'
          ) {
            console.log('Handler was called!');
            this.showExitConfirm();
          } else if (this.router.url == '/module-list') {
            this.router.navigateByUrl('validate-branch');
          } else if (this.router.url == '/home/dashboard') {
            this.router.navigateByUrl('module-list');
          } else {
            this.router.navigateByUrl('home/dashboard');
          }
        } else {
          this.router.navigateByUrl('login');
        }
      });
      this._permisiion
        .checkPermission(this._permisiion.PERMISSION.BLUETOOTH_CONNECT)
        .then(
          (_res: any) => {
            if (!_res.hasPermission) {
              this._permisiion.requestPermission(
                this._permisiion.PERMISSION.BLUETOOTH_CONNECT
              );
            }
          },
          (err) => {
            this._permisiion.requestPermission(
              this._permisiion.PERMISSION.BLUETOOTH_CONNECT
            );
          }
        );
    });
  }
  showExitConfirm() {
    this._alertController
      .create({
        header: 'Lozics App Termination',
        message: 'Do you want to close the app?',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay',
            role: 'cancel',
            handler: () => {
              console.log('Application exit prevented!');
            },
          },
          {
            text: 'Exit',
            handler: () => {
              navigator['app'].exitApp();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  _validateDomain = async (loginform, _accessKey) => {
    debugger;
   // let loading = await this.comn.presentLoadingDefault();
   // loading.present();
    // let _domain =
    //   loginform.user && loginform.user.includes('@')
    //     ? loginform.user.split('@')[1]
    //     : '';
    this.api._validateAccessKey(_accessKey).subscribe(
      async (_res: any) => {
        debugger;
      //  loading.dismiss();
        if (!_res['data'].length) {
          this.comn.showError('Access key does not exist.');
          return false;
        }
        let _data = JSON.stringify(_res['data'][0]);
        this.api._baseToken =
          this._encrpt.setEncryptedData(_data, 'lozicsByBng').toString() + '=';
        // await this.GoToDashboard(loginform);
        this.api._accessKey = _accessKey;
        this._getUserProfile(loginform);
        console.log(this.api._baseToken);
      },
      (err) => {
      //  loading.dismiss();
        console.error(err);
        // this.comn.showError(err.error);
        this.router.navigateByUrl('login');
      }
    );
  };

   

  _getUserProfile = async (loginform) => {
    //let loading = await this.comn.presentLoadingDefault();
   // loading.present();
    this.api._doLogin(loginform).subscribe(
      async (res: any) => {
        debugger;
     //   loading.dismiss();
        const data = res.data;
        if (data) {
          if (data[0][0]['USR_ALLOWMOBILELOGIN']) {
            const _user = JSON.stringify(loginform);
            this._setting.store(
              'user',
              this._encrpt.setEncryptedData(_user, 'lozicsByBng').toString()
            );
            // this.api.userData=data;
            this.api.userData.USER = data[0];
            this.api.userData.COMPANY = data[1];
            this.api.userData.FYEAR = data[2];
            this._shared.publishSomeData(this.api.userData);
            if (this.api.userData.USER[0]['USR_FIRSTLOGIN'] == 'Y') {
              this.navigateToChangePasswordPage();
            } else {
              this.navigateToHomePage();
            }
          } else {
            this.comn.showError(
              'Unauthorized Access, Please Contact The Administrator'
            );
            this._setting.store('user', null);
            this._shared.publishSomeData(null);
            this.router.navigateByUrl('login');
            return false;
          }
        } else {
          this.comn.showError('Please Enter Valid Username And Password');
          this._setting.store('user', null);
          this._shared.publishSomeData(null);
          this.router.navigateByUrl('login');
          return false;
        }

        console.log(res);
      },
      (error) => {
        //  loading.dismiss();
        this._setting.store('user', null);
        //this.comn.showError(error.error);
      }
    );
  };

  navigateToChangePasswordPage() {
    this.router.navigate(['home/change-password']);
  }
  navigateToHomePage() {
    this.router.navigate(['validate-branch']);
  }

  _logout = () => {
    this._setting.store('user', null);
    this._setting.store('branch', null);
    this._setting.clear();
    this._share.publishSomeData(null);
  };
  _menuRights = (menuRights) => {
    this.api._menuRightsClick(menuRights);
  };
}
