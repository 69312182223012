import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import * as moment from 'moment';


@Injectable({providedIn:'root'})
export class CommonfunctionProvider {
	commonSettings: any;
	isMissed: Boolean = false;
	constructor(public alertCtrl: AlertController, public loadingCtrl: LoadingController,public _toaster:ToastController) {
		console.log('Hello CommonfunctionsProvider Provider');
	}

	createAlert=async (title, subtitle)=> {
		let alert = this.alertCtrl.create({
			header: title,
			subHeader: subtitle,
			buttons: ['OK']
		});
		return alert;
	}

	presentLoadingDefault =async ()=> {
		let loading = this.loadingCtrl.create({
			message: 'Please wait...'
		});
		return loading;
	}

	syncLoadingDefault() {
		let loading = this.loadingCtrl.create({
			message: 'Please wait, Synchronizing Data....'
		});
		return loading;
	}

	date_format(date, timeZoneOffset, format) {
		var formattedDate = '';
		timeZoneOffset = -timeZoneOffset;
		if (date) {
			var todaysDate = moment().format("MM-DD-YYYY");
			var todayDateTime = moment().format("MM-DD-YYYY hh:mmA");
			var givenDate = moment(date).format("MM-DD-YYYY");
			var givenDateTime = moment(date).format("MM-DD-YYYY hh:mmA");

			if (todaysDate == givenDate) {
				if (format == 'MMM DD, YYYY') {
					formattedDate = timeZoneOffset ? 'Today' : moment(date).format("hh:mm A");
				} else if (format == 'hh:mm A') {
					formattedDate = timeZoneOffset ? moment(date).zone(timeZoneOffset).format("hh:mm A") : moment(date).format("hh:mm A");
				} else {
					formattedDate = timeZoneOffset ? 'Today - ' + moment(date).zone(timeZoneOffset).format("hh:mm A") : moment(date).format("hh:mm A");
				}
			}
			else {
				if (format == 'MMM DD, YYYY') {
					formattedDate = timeZoneOffset ? moment(date).zone(timeZoneOffset).format("MMM DD, YYYY") : moment(date).format("MMM DD, YYYY");
				} else if (format == 'hh:mm A') {
					formattedDate = timeZoneOffset ? moment(date).zone(timeZoneOffset).format("hh:mm A") : moment(date).format("hh:mm A");
				} else {
					formattedDate = timeZoneOffset ? moment(date).zone(timeZoneOffset).format("MMM DD, YYYY hh:mm A") : moment(date).format("MMM DD, YYYY hh:mm A");
				}
			}
			return formattedDate;
		}
		else {
			return formattedDate;
		}
	}

	b64toBlob(b64Data) {
		let contentType = b64Data.split(',')[0].split(':')[1].split(';')[0] || '';
		var sliceSize = 256;

		var byteCharacters = atob(b64Data.split(',')[1]);
		var byteArrays = [];

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);
			var byteNumbers = new Array(slice.length);

			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			var byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}

	presentLoadingForSpeak() {
		let loading = this.loadingCtrl.create({
			message: 'Please speak...'
		});
		return loading;
	}
	dataURItoBlob(dataURI) {

		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString = atob(dataURI.split(',')[1]);
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		var bb = new Blob([ab], {
			"type": "image/jpeg"
		});
		return bb;
	}

	dataURItoBlobDocuments(dataURI) {

		let contentType = dataURI.split(',')[0].split(':')[1].split(';')[0] || '';
		console.log(contentType);
		
		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString = atob(dataURI.split(',')[1]);
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		var bb = new Blob([ab], {
			"type": contentType
		});
		return bb;
	}

	dataURItoBlobVideo(dataURI) {
		let contentType = dataURI.split(',')[0].split(':')[1].split(';')[0] || '';
		console.log(contentType);
		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString = atob(dataURI.split(',')[1]);
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		var bb = new Blob([ab], {
			"type": contentType
		});
		return bb;
	}


	showError=async (_msg)=>{
		const toast = await this._toaster.create({
			message: _msg,
			duration: 2000,
			position: 'top',
			color: 'danger',
			cssClass: 'toast-danger'
		  });
		  toast.present();
	}

	showSuccess=async (_msg)=>{
		const toast = await this._toaster.create({
			message: _msg,
			duration: 2000,
			position: 'top',
			color: 'success',
			cssClass: 'toast-success'
		  });
		  toast.present();
	}
}