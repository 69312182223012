import { environment } from './../../environments/environment';
import { indent } from './../model/indent.model';
import { SettingsProvider } from './settings';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class ApiserviceProvider {

  isSearched: boolean = false;
  searchTerms: string = '';
  settingsconfig: any;
  title: string = '';
  _isLoading: boolean = false;
  _baseToken: any;
  _accessKey: string = '';
  _sideMenu: any = [];
  _companyData: any = {};
  _branch: any = {};
  userData = {
    USER: [],
    COMPANY: [],
    FYEAR: [],
  };
  brnDetail = {
    brnID: 0,
    fyr: 0,
    cmpID: 0,
    brnName: '',
  };
  _menuRights = [];

  //  readonly url = 'http://localhost:1337/api';

  readonly url = `http://103.205.66.47/NodeApi/api`;

  constructor(public _http: HttpClient, public settings: SettingsProvider) {
    console.log('Lozics Backend Provider');
  }

  getfocus = (controlID: string) => {
    document.getElementById(controlID).focus();
  };
  ConvertToNumber(val, decimalplace?) {
    if (!decimalplace) {
      decimalplace = 2;
    }
    let value = Number(Number(val).toFixed(decimalplace));
    let final = isNaN(value) ? 0 : Number(value);
    return final;
  }
  /**
   *  Login & Validate Branch Module Begin
   */

  _validateDomain = (domain) => {
    // debugger;
    return this._http.get(
      `${environment.API_BASE_URL}/User/GetCompanyByDomain?domain=${domain}`,
      {}
    );
  };

  _validateAccessKey = (_key:string) => {
    // debugger;
    return this._http.get(
      `${environment.API_BASE_URL}/User/GetAccessKey?Code=${_key}`,
      {}
    );
  };

  _doLogin = (loginModel) => {
    // debugger;
    return this._http.get(
      `${environment.API_BASE_URL}/User/GetUserInfo?Username=${loginModel.user}&Password=${loginModel.pass}`,
      {}
    );
  };
  _doAuth = (loginModel) => {
    // debugger;
    return this._http.get(
      `${
        environment.API_BASE_URL
      }/User/UserAuth?Username=${loginModel.user.trim()}`,
      {}
    );
  };

  _changePassword = (_model) => {
    // debugger;
    return this._http.get(
      `${environment.API_BASE_URL}/User/UserAuthPassChange?Empcode=${_model.empcode}&Oldpass=${_model.oldpass}&Newpass=${_model.pass}`,
      {}
    );
  };

  _getBranch = (userInfoModel) => {
    return this._http.get(
      `${environment.API_BASE_URL}/User/GetUserBranchInfo?Usercode=${userInfoModel.ucode}&Cmpcode=${userInfoModel.uccode}`,
      {}
    );
  };
  _getModuleList = (role) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Module/GetUserModule?Role=${role}`,
      {}
    );
  };

  _getModuleWiseMenu = (role, module) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Module/GetModuleWiseMenu?Role=${role}&Module=${module}`,
      {}
    );
  };
  /**
   *  Login & Validate Branch Module Close
   */

  /**
   * Indent  Module Begin
   */
  _getIndentDropdown = () => {
    return this._http.get(
      `${environment.API_BASE_URL}/Indent/GetDDLIndent`,
      {}
    );
  };
  // return this._http.get(`${environment.API_BASE_URL}/Indent/GetDDLIndent`, {});
  _acmAutocomplete = (_acmModel) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Indent/GetCustomerACM?search=${_acmModel.search}&table=${_acmModel.table}&alias=${_acmModel.alias}&actcode=${_acmModel.actcode}`,
      {}
    );
  };

  _acmAutocompleteNew = (_acmModel) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Indent/GetCustomerACMNew?search=${_acmModel.search}&bcode=${_acmModel.bcode}&user=${_acmModel.user}&actcode=${_acmModel.actcode}`,
      {}
    );
  };

  _getAcmContact = (acmcode) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Indent/GetAcmContact?acmcode=${acmcode}`,
      {}
    );
  };
  _getLoadingPoint = (_loadingModel) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Indent/GetLoadingPoint?name=${_loadingModel.name}&condition=${_loadingModel.condition}`,
      {}
    );
  };
  _getEmployeeAutoComplete = (_emp) => {
    return this._http.get(
      `${environment.API_BASE_URL}/indent/GetEmployeeAutoComplete?name=${_emp.name}&condition=${_emp.condition}`,
      {}
    );
  };
  _getVnoPrefix = (indent) => {
    return this._http.get(
      `${environment.API_BASE_URL}/indent/GetVnoPrefix?branch=${indent.brncode}&Fyear=${indent.fyrcode}&Date=${indent.vdate}&Vtype=${indent.vtype}`,
      {}
    );
  };

  _vnoValidate = (indent) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Indent/ValidateVno?Bcode=${indent.bcode}&Prefix=${indent.prefix}&Vno=${indent.vno}&Fyear=${indent.fcode}&User=${indent.user}&Vtype=${indent.vtype}`,
      {}
    );
  };

  _getSegmentAutoComplete = (_search) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Indent/GetSegmentAutoComplete?search=${_search}`,
      {}
    );
  };
  _getSegmentById = (mstcode) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Indent/GetSegmentById?mstcode=${mstcode}`,
      {}
    );
  };
  _getIndentRateHistory = (_rateModel) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Indent/GetIndentRateHistory?Acmcode=${_rateModel.acmcode}&Fromstation=${_rateModel.fromstation}&Tostation=${_rateModel.tostation}&ldtcode=${_rateModel.ldtcode}&mkey=${_rateModel.mkey}`,
      {}
    );
  };
  _getOutstanding = (_outstanding) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Indent/GetOutstanding?Acmcode=${_outstanding.acmcode}&Fyear=${_outstanding.fyear}&Vdate=${_outstanding.vdate}`,
      {}
    );
  };

  _postIndent = (indent) => {
    return this._http.post(
      `${environment.API_BASE_URL}/Indent/postIndent`,
      indent
    );
  };
  /**
   * Indent  Module End
   */

  /**
   * Pending Indent  Module Begin
   */
  _getPendingIndent = (_pending) => {
    return this._http.get(
      `${environment.API_BASE_URL}/PendingApproval/GetPendingIndent?Bcode=${_pending.bcode}&Fcode=${_pending.fcode}&UserCode=${_pending.usercode}`,
      {}
    );
  };

  _getPendingIndentByMkey = (mkey) => {
    return this._http.get(
      `${environment.API_BASE_URL}/PendingApproval/GetPlacementIndentByMkey?Mkey=${mkey}`,
      {}
    );
  };

  _updateIndentStatus = (_indentModel) => {
    return this._http.get(
      `${environment.API_BASE_URL}/PendingApproval/UpdateIndentStatus?Mkey=${_indentModel.mkey}&Status=${_indentModel.status}&User=${_indentModel.user}&Remark=${_indentModel.remark}`,
      {}
    );
  };
  /**
   * Pending Indent  Module End
   */

  /**
   * Placement   Module Begin
   */

  _getPendingPlacement = (_placement) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Placement/GetPendingPlacement?Bcode=${_placement.bcode}&Fcode=${_placement.fcode}&UserCode=${_placement.usercode}`,
      {}
    );
  };

  _getVehicleDriverInfoAutoComplete = (_placement) => {
    let url = '';
    if (_placement.flag == 'V') {
      url = `${environment.API_BASE_URL}/Placement/GetVehicleDriverInfoAutoComplete?Search=${_placement.search}&Flag=${_placement.flag}&Source=${_placement.source}`;
    } else if (_placement.flag == 'D') {
      url = `${
        environment.API_BASE_URL
      }/Placement/GetVehicleDriverInfoAutoComplete?Search=${
        _placement.search
      }&Flag=${_placement.flag}&Source=${null}`;
    }
    return this._http.get(url, {});
  };
  _getNoOfPendingPOD = (_vcode) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Placement/GetNoOfPendingPOD?VhlCode=${_vcode}`,
      {}
    );
  };

  _postPlacement = (_placement) => {
    return this._http.post(
      `${environment.API_BASE_URL}/Placement/PostPlacement`,
      _placement
    );
  };

  /**
   * Placement   Module End
   */

  /**
   * Report   Module Begin
   */

  _getPlacementReport = (_report) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Report/GetPlacementReport?Bcode=${_report.bcode}&Fcode=${_report.fcode}&Status=${_report.status}&FromDate=${_report.fromdate}&ToDate=${_report.todate}&UserCode=${_report.usercode}`,
      {}
    );
  };

  _getIndentReport = (_report) => {
    return this._http.get(
      `${environment.API_BASE_URL}/Report/GetIndentReport?Bcode=${_report.bcode}&Fcode=${_report.fcode}&Status=${_report.status}&FromDate=${_report.fromdate}&ToDate=${_report.todate}&UserCode=${_report.usercode}`,
      {}
    );
  };
  _menuRightsClick = (menuArray: any) => {
    this._menuRights = [];
    this._menuRights = Array.from(menuArray);
    console.log(this._menuRights);
  };

  _getRouteDistance = (_model: any) => {
    return this._http.get(
      `${environment.API_BASE_URL}Indent/GetRouteDistance?fromStn=${_model.from}&toStn=${_model.to}&loadType=${_model.loadtype}`,
      {}
    );
  };
  sendOtp = (mobile) => {
    return this._http.get(
      `${environment.API_BASE_URL}User/SendOtp?EmailId=${mobile}`,
      {}
    );
  };

  forgetPassword = (email, NewPassword, USR_ID) => {
    return this._http.get(
      `${environment.API_BASE_URL}User/ForgetPassword?UserId=${USR_ID}&NewPassword=${NewPassword}&email=${email}`,
      {}
    );
  };

  //http://localhost:4250/api/Authenticate/SendOtpToMobile?MobileNo=9555025362

  _getOtp = (mobile: number) => {
    debugger
    return this._http.get(
      `${environment.API_BASE_URL}Authenticate/SendOtpToMobile?MobileNo=${mobile}`,
      {}
    );
  };


  //http://localhost:4250/api/Authenticate/ValidateOTP?MobileNo=9555025362&OtpGen=5216
  _verifyOtp = (mobile: number, OtpGen: number) => {
    return this._http.post(
      `${environment.API_BASE_URL}/Authenticate/ValidateOTP?MobileNo=${mobile}&OtpGen=${OtpGen}`,
      {}
    );
  };


  getIndentList(model: any) {
    debugger
    return this._http.post(`${environment.API_BASE_URL}IndentUpdate/List`, model);
  }


  _StatusUpdate = (Mkey,StsCode,UserId) => {
    return this._http.get(`${environment.API_BASE_URL}IndentUpdate/Actions?Mkey=${Mkey}&StsCode=${StsCode}&UserId=${UserId}`)
  }

}
