import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-create-action',
  templateUrl: './create-action.component.html',
  styleUrls: ['./create-action.component.scss'],
})
export class CreateActionComponent implements OnInit {
  @Output() onCreate= new EventEmitter<any>();
  constructor() { }

  ngOnInit() {}

  _onCreate=()=>{
    this.onCreate.emit();
  }

}
