import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
})
export class PagingComponent implements OnInit {
  @Input() curPage: number = 0;
  @Input() numberOfPages: number = 0;
  @Input() pageSize = 3;
  curPageRow: number = 0;
  @Input() pages: any = [];
  @Output() onChangePage = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}
  _onChangePage = (_page, i) => {
    if (i == 2 && _page !== this.numberOfPages) {
      this.curPageRow++;
      this.pageSize++;
    } else if (i == 0 && _page !== 1) {
      this.curPageRow--;
      this.pageSize--;
    }

    this.onChangePage.emit(_page);
  };
  _prev = () => {
    this.curPageRow = 0;
    this.pageSize = 3;
    this.onChangePage.emit(1);
  };
  _next = () => {
    this.curPageRow = this.numberOfPages - 3;
    this.pageSize = this.numberOfPages;
    this.onChangePage.emit(this.numberOfPages);
  };
}
