import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() title;
  @Input() isBackButton=false;
  @Input() backUrl='home/dashboard';
  @Input() isReport=false;
  @Input() recordsLength=0;
  @Input() isNotification=false;
  @Input() notificationLength=0;
  @Input() _requestFrom:string='';
  constructor( private _nav: NavController,) { console.log(this.isBackButton);
  }

  ngOnInit() {}

  _navigateToNotification=()=>{
    this._nav.navigateRoot(`/home/notifications/${this._requestFrom}`, {
      animationDirection: 'forward',
    });
  }
}
