import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { SettingsProvider } from './providers/settings';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingScreenInterceptor } from './providers/InterceptorLoad';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CommonModule } from '@angular/common';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {BluetoothSerial} from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx'; 

export function provideSettings(storage: Storage) {
  console.log('settings provider setting setting');
  return new SettingsProvider(storage, {
    projectName: 'lozicsapp',
    host: '',
  });
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    NgxWebstorageModule.forRoot(),
    CommonModule,
    AutocompleteLibModule,
    BrowserModule,
    IonicModule.forRoot({ _forceStatusbarPadding: true }),
    AppRoutingModule,
    ComponentsModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [
    BluetoothSerial,
    PDFGenerator,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: SettingsProvider, useFactory: provideSettings, deps: [Storage] },
    AndroidPermissions
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
